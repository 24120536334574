import React from 'react';
import uuidv4 from 'uuid/v4';

const MemberCircle = (props) => {
    const id = `_${uuidv4()}`;
    return (
        <svg className="icon" viewBox="0 0 32 32" width="1em" height="1em">
            <defs>
                <circle id={`${id}a`} cx={16} cy={16} r={16} />
            </defs>
            <g fill="none" fillRule="evenodd">
                <use fill={props.fill} xlinkHref={`#${id}a`} />
                <path
                    d="M20.497 22.537H11.5v-2.816c0-1.327.943-2.45 2.189-2.738a2.63 2.63 0 0 1 .558-.07c.024-.002.048-.002.072-.002h3.396a2.695 2.695 0 0 1 1.056.21 2.813 2.813 0 0 1 1.725 2.6v2.816zm-2.781-6.2H14.32c-1.86 0-3.393 1.524-3.393 3.384v3.39h10.144v-3.39c0-1.86-1.495-3.384-3.355-3.384zm-1.698-2.281a2.299 2.299 0 0 1-2.296-2.296 2.299 2.299 0 0 1 2.296-2.296 2.3 2.3 0 0 1 2.297 2.296 2.3 2.3 0 0 1-2.297 2.296m0-5.167a2.874 2.874 0 0 0-2.87 2.871 2.873 2.873 0 0 0 2.87 2.87 2.873 2.873 0 0 0 2.87-2.87 2.874 2.874 0 0 0-2.87-2.871"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
};

export default MemberCircle;
