import React from 'react';

const Invita = ({ fill, size, className }) => (
    <svg
        className={`icon ${className || ''}`}
        viewBox="-7427 -2304 27.474 27.688"
        width={size || '1em'}
        height={size || '1em'}
    >
        <defs />
        <g id="Group_671" data-name="Group 671" transform="translate(-7426.996 -2304)">
            <g id="Group_671-2" data-name="Group 671" transform="translate(-.004)">
                <path
                    fill={fill || 'currentColor'}
                    d="M13.77 27.688c-1.392 0-4.908-.073-6.885-.879-1.318-.586-2.857-2.344-4.1-4.834a28.514 28.514 0 0 1-2.637-9.6 36.08 36.08 0 0 1 .364-9.518A3.507 3.507 0 0 1 2.123.366 6.085 6.085 0 0 1 4.833 0h17.873a5.6 5.6 0 0 1 2.637.366 3.379 3.379 0 0 1 1.611 2.49 36.08 36.08 0 0 1 .366 9.522 28.514 28.514 0 0 1-2.637 9.6c-1.245 2.49-2.71 4.248-4.1 4.834-1.906.803-5.421.876-6.813.876zM4.833 2.783a6.378 6.378 0 0 0-1.392.073 2.394 2.394 0 0 0-.146.586 31.648 31.648 0 0 0-.366 8.717c.659 7.178 3.882 11.573 4.981 12.086a18.49 18.49 0 0 0 5.86.659 20.962 20.962 0 0 0 5.86-.659c1.1-.439 4.322-4.908 4.981-12.086a30.924 30.924 0 0 0-.366-8.643c-.073-.439-.146-.586-.22-.659a6.006 6.006 0 0 0-1.392-.073h-17.8z"
                    transform="translate(.004)"
                />
            </g>
            <g id="Group_672" data-name="Group 672" transform="translate(5.053 5.787)">
                <path
                    fill={fill || 'currentColor'}
                    d="M15.777 25.055c3.662.366 7.105-3.662 8.277-10.255H7.5c1.172 6.592 4.615 10.621 8.277 10.255z"
                    transform="translate(-7.061 -9.746)"
                />
                <path
                    fill={fill || 'currentColor'}
                    d="M24.333 7.9H6.9a23.338 23.338 0 0 0 .22 3.3h17.067c.073-1.029.146-2.128.146-3.3z"
                    transform="translate(-6.9 -7.9)"
                />
            </g>
        </g>
    </svg>
);

export default Invita;
