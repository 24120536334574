import React from 'react';

const Export = (props) => (
    <svg className="icon" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
        <g fill="#FFF" fillRule="evenodd">
            <path d="M14.603 7.9v1.498h3.751v12.968H5.605V9.398h3.753V7.901H4v15.96h15.96V7.902z" />
            <path d="M11.98 0L6.71 5.504l1.488.914 2.89-3.017V15.93h1.782V3.399l2.89 3.019 1.49-.914z" />
        </g>
    </svg>
);

export default Export;
